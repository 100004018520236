@use '../../../css' as *;

footer.site-footer {
  background-color: $bg-color-footer;
  padding: ($gap * 1.5)  0;
  font-size: $font-size-footer;
  line-height: $line-height-footer;

  a {
    &:hover,
    &:focus,
    &:active {
      color: $color-brand-primary;
    }
  }
}

.nav-footer {

  > ul {
      display: flex;
      align-content: stretch;

      > li {
          flex: 1 1 auto;

          > *:not(ul, button) {
              color: $color-text;
              font-size: $font-size-footer;
              font-weight: 500;
              text-transform: uppercase;
          }
      }
  }

  ul li > *:not(ul, button) {
      display: block;
      padding: 6px 0;
      margin: 0;
  }
}

.nav-func {
  > ul {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      li {
          a { padding: 0 10px 0 0; }

          &:first-child a { padding-left: 0; }

          &:not(:last-child):after {
              content: "//";
              padding-right: 10px;
          }
      }
  }
}