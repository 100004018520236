@use "variables.scss" as *;
@use "mixins.scss" as *;

// ---------- LINKS ----------

a:not(:where(nav *)),
span.link {
    color: $color-link;
    font-style: italic;
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
        color: $color-link-hover;

        .wd-feed &       { color: $color-wd-feed; }
        .wd-food &       { color: $color-wd-food; }
        .wd-pharma-api & { color: $color-wd-pharma-api; }
        .wd-pharma-fdf & { color: $color-wd-pharma-fdf; }
    }
}

main > a {
    color: $color-text;
    font-style: normal;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        color: $color-text;
    }
}

// tel and nav links
a:not(:where(nav *))[href^="tel:"],
a:not(:where(nav *))[href^="mailto:"] {
    color: $color-text;
    font-style: normal;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        color: $color-link-hover;
        text-decoration: underline;

        .wd-feed &       { color: $color-wd-feed; }
        .wd-food &       { color: $color-wd-food; }
        .wd-pharma-api & { color: $color-wd-pharma-api; }
        .wd-pharma-fdf & { color: $color-wd-pharma-fdf; }
    }
}

// links around boxes etc.
.column > a {
    color: $color-text;
    font-style: normal;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        color: $color-text;
    }
}

// anchor links
a.link-anchor {
    scroll-margin-top: 48px;

    .cms-toolbar-expanded & {
        scroll-margin-top: 94px;
    }

    @include from ($mobile-menu-breakpoint) {
        scroll-margin-top: 78px;

        .cms-toolbar-expanded & {
            scroll-margin-top: 124px;
        }
    }
}
