@use "variables.scss" as *;

nav {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            margin: 0;
            padding: 0;

            a {
                box-shadow: none;
                color: $color-text;
                text-decoration: none;

                &:hover,
                &:focus,
                &:active {
                    color: $color-text;
                    text-decoration: none;
                }
            }
        }
    }
}