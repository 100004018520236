@use "variables.scss" as *;
@use "mixins.scss" as *;

figure,
picture {
    margin: 0;
}

img {
    display: block;
    width: 100%;
}


img:not(:where(.box *, .mask *)) {
    border-top-left-radius: $border-radius-base;
}

header, footer, .hero {
    img {
        border-top-left-radius: 0;
    }
}

// floated images

figure,
picture {
    // all
    &[class*="float-"] {
        margin: 0 0 $gap;

        @include from($tablet) {
            float: left;
            margin-right: $gap;
            width: 50%;

            + * {
                margin-top: 0;
            }
        }
    }

    // floated right
    &.float-right {
        @include from($tablet) {
            float: right;
            margin-left: $gap;
            margin-right: 0;
        }
    }
}