@use '../../../css' as *;

blockquote {
    display: flex;
    font-style: italic;
    font-size: $font-size-quote-mobile;
    line-height: $line-height-quote;
    margin: 0;
    @include auto-color;

    &:not(:last-child) {
        margin-bottom: $gap * 2.5;
    }

    &:before {
        background: transparent url("../../#{$shapes-folder}shape-quote-wd-group.svg") no-repeat 0 0;
        background-size: contain;
        content: "";
        display: block;
        flex-grow: 0;
        flex-shrink: 0;
        margin-right: $gap;
        height: 32px;
        width: 50px;

        .wd-feed &       { background: transparent url("../../#{$shapes-folder}shape-quote-wd-feed.svg") no-repeat 0 0; }
        .wd-food &       { background: transparent url("../../#{$shapes-folder}shape-quote-wd-food.svg") no-repeat 0 0; }
        .wd-pharma-api & { background: transparent url("../../#{$shapes-folder}shape-quote-wd-pharma-api.svg") no-repeat 0 0; }
        .wd-pharma-fdf & { background: transparent url("../../#{$shapes-folder}shape-quote-wd-pharma-fdf.svg") no-repeat 0 0; }
    }
}

@include from($tablet) {
    blockquote {
        font-size: $font-size-quote;

        &:before {
            margin-right: 25px;
            height: 82px;
            width: 125px;
        }
    }
}