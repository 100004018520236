@use '../../../css' as *;

.nav-lang {
    margin-left: auto;
    margin-right: 10px;

    @include from ($mobile-menu-breakpoint) {
        margin: 0 -6px 0 $gap;
    }

    ul {
        display: flex;

        li {
            display: flex;
            
            a {
                display: flex;
                padding: 0 6px;
                position: relative;

                &:after {
                    border-bottom: 1px solid currentColor;
                    bottom: 0;
                    content: "";
                    display: none;
                    left: 6px;
                    position: absolute;
                    right: 0;
                    width: 18px;
                }

                &:hover, &:focus, &:active {

                    &:before {
                        color: $color-text;
                    }

                    &:after {
                        display: block;
                    }
                }
            }

            &.current {
                a {
                    color: $color-nav-lang-current;

                    &:hover,
                    &:focus,
                    &:active {
                        color: $color-nav-lang-current;
                        &:after {
                            content: none;
                        }
                    }
                }
            }

            + li:before {
                content: "|";
                margin-left: 1px;
            }
        }
    }
}