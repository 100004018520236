@use '../../../css' as *;

.teaser-staff {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    > * {
        flex: 1 1 100%;
        width: 100%;
    }

    .text {
        padding: $gap;

        :first-child { margin-top: 0; }
        :last-child  { margin-bottom: 0; }
    }

    p.title {
        margin-bottom: -1rem;
    }
}

@include from($mobile-big) {
    .teaser-staff {
        flex-wrap: nowrap;

        > * {
            flex: 1 1 50%;
            width: 50%;
            height: auto;
        }
    }
}