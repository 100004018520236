@use '../../../css' as *;

body {
    margin-top: 78px;
}

header.site-header {
    background-color: $bg-color-header;
    position: fixed;
    font-size: $font-size-header;
    padding: 15px 0;
    z-index: 20;
    top: 0;
    left: 0;
    right: 0;

    .cms-toolbar-expanded & {
        margin-top: 46px;
    }

    .logo {
        margin-right: $gap * 2;
        width: 150px;
        z-index: 1;
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &.shrink {
        padding-top: 0;
        padding-bottom: 0;

        .logo img {
            height: 28px;
            width: 130px;
        }
    }
}

@include from ($mobile-menu-breakpoint) {
    body {
        margin-top: 121px;
    }

    header.site-header {
        padding: 45px 0 30px;

        .logo {
            margin-right: auto;

            img {
                height: auto;
                width: 215px;
            }
        }

        &.shrink {
            padding-top: 20px;
            padding-bottom: 20px;

            .logo img {
                height: auto;
                width: 175px;
            }
        }
    }
}
