@use '../../../css' as *;

.nav-main {
    ul {
        li {
            a {
                color: $color-nav-main;

                &:hover,
                &:focus,
                &:active {
                    color: $color-nav-main-hover;
                }
            }
        }
    }
}

.nav-mobile-opener {
    background-color: transparent;
    box-sizing: border-box;
    display: block;
    flex: 0 0 48px;
    height: 48px;
    margin-right: -14px;
    order: 10;
    padding: 14px;
    position: relative;
    width: 48px;

    .nav-mobile-line {
        border-radius: 2px;
        border: 1px solid $color-text;
        box-sizing: content-box;
        left: 14px;
        right: 14px;
        display: block;
        height: 0px;
        position: absolute;
        width: 18px;

        &:first-child   { top: 16px; }
        &:nth-child(2)  { top: 23px; }
        &:nth-child(3)    { bottom: 16px; }
    }
}

@include until ($mobile-menu-breakpoint) {
    body.menu-open {
        overflow: hidden;
        height: 100vh;

        main {
            overflow: hidden;
            height: 100vh;
        }
    }

    .nav-main {
        align-content: flex-start;
        background-color: $bg-color-header;
        bottom: 0;
        display: none;
        flex-wrap: wrap;
        left: 0;
        overflow-y: scroll;
        overflow-x: hidden;
        padding-top: 0;
        position: fixed;
        right: 0;
        top: 78px;
        width: 100%;

        .shrink & {
            padding-top: 10px;
            top: 48px;
        }

        &.open {
            display: flex;
        }

        .search {
            flex: 0 0 100%;
            order: 1;
        }

        // general
        ul {
            order: 2;

            li {
                min-height: 48px;
                position: relative;

                &.current {
                    > a {
                        color: $color-nav-main-current-mobile;
                    }
                }

                a {
                    border-bottom: 1px solid $border-color-nav-main;
                    border-top: 1px solid $border-color-nav-main;
                    display: block;
                    margin-bottom: -1px;
                    padding: 13px 48px 13px $gap;
                    position: relative;
                    z-index: 1;
                }

                ul {
                    display: none;
                }

                .open + ul {
                    display: block;
                }
            }

            button {
                background: transparent url("#{$icon-chevron-right}") no-repeat center right $gap;
                background-size: 10px 10px;
                display: block;
                height: 48px;
                padding: 0;
                position: absolute;
                right: 0;
                top: 1px;
                width: 48px;
                z-index: 1;

                color: transparent;
                text-indent: -9999px;

                &.open {
                    transform: rotate(90deg);
                }
            }
        }

        // 1st level
        > ul {
            width: 100%;

            > li.current {
                > a {
                    border-left: 3px solid $color-nav-main-current-mobile;
                    padding-left: $gap - 3px;
                }
            }
        }

        // 2nd level
        > ul > li > ul {
            margin-left: $gap * 2;

            > li {
                img {
                    display: block;
                    height: 26px;
                    margin-bottom: -2px;
                    margin-top: -2px;
                    margin-left: 2px;
                    width: auto;
                }

                > {
                    img { padding: 13px 48px 13px 2px; }

                    a { padding-left: 0; }
                }

                &.current {
                    > a {
                        img { margin-left: 12px; }
                    }
                }
    
                // 3rd level
                > ul {
                    font-size: $font-size-nav-main-sub;

                    > li {
                        > a {
                            display: flex;
                            padding: 14px 48px 14px 2px;

                            &::before {
                                content: "\00B7";
                                margin-right: 8px;
                            }
                        }

                        &.current {
                            > a { font-weight: bold; }
                        }
                    }
                }

                &.wd-pharma-api {
                    a:hover,
                    a:focus,
                    a:active,
                    &.current > ul > li.current > a { color: $color-wd-pharma-api; }
                    &.current > a { border-left: 3px solid $color-wd-pharma-api; }
                }

                &.wd-pharma-fdf {
                    a:hover,
                    a:focus,
                    a:active,
                    &.current > ul > li.current > a { color: $color-wd-pharma-fdf; }
                    &.current > a { border-left: 3px solid $color-wd-pharma-fdf; }
                }

                &.wd-feed {
                    a:hover,
                    a:focus,
                    a:active,
                    &.current > ul > li.current > a { color: $color-wd-feed; }
                    &.current > a { border-left: 3px solid $color-wd-feed; }
                }

                &.wd-food {
                    a:hover,
                    a:focus,
                    a:active,
                    &.current > ul > li.current > a { color: $color-wd-food; }
                    &.current > a { border-left: 3px solid $color-wd-food; }
                }
            }
        }
    }
}


@include from ($mobile-menu-breakpoint) {
    .nav-mobile-opener {
        display: none;
    }
    
    .nav-main {
        display: flex;
        justify-content: space-between;
        width: 605px;

        // 1st level
        > ul {
            display: flex;
            justify-content: space-between;
            position: relative;
            width: 440px;

            > li {
                position: relative;

                button { display: none; }

                > a {
                    padding-bottom: 20px;

                    &:hover,
                    &:focus,
                    &:active {
                        color: $color-nav-main-sub-hover;
                    }

                    &:after {
                        border-bottom: 2px solid transparent;
                        content: "";
                        display: block;
                        padding-top: 4px;
                    }
                }

                &.current > a:after {
                    border-bottom-color: $color-brand-primary;
                }

                // 2nd level - submenu
                > ul {
                    z-index: -1;
                }

                &:hover > ul {
                    display: flex;
                }

                > ul > li.current > a {
                    color: $color-nav-main-sub-current;
                    font-weight: bold;
                }

                // 2nd level - submenu brands
                &:nth-child(2) > ul {
                    flex-wrap: nowrap;
                    gap: $gap;
                    left: -220px;
                    width: 800px;

                    > li {
                        flex: 1 1 25%;
                    }
                }

                // 2nd level - all other submenus
                > ul {
                    background-color: $bg-color-header;
                    display: none;
                    flex-wrap: wrap;
                    gap: 0;
                    left: -24px;
                    position: absolute;
                    padding: 34px $gap * 1.5 $gap * 1.5;
                    top: 30px;
                    width: 180px;

                    .shrink & {
                        top: 15px;
                    }

                    img {
                        height: 25px;
                        margin: 0 0 14px;
                        width: auto;
                    }

                    > li {
                        flex: 0 0 100%;
                        padding: 5px 0;

                        a {
                            display: block;

                            &:hover,
                            &:focus,
                            &:active {
                                color: $color-nav-main-sub-hover;
                            }
                        }

                        // 3rd level
                        > ul {
                            > li {
                                a {
                                    box-sizing: border-box;
                                    padding: 5px 0;
                                }

                                &.current a {
                                    color: $color-nav-main-sub-current;
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}