@use "mixins.scss" as *;
@use "variables.scss" as *;

section {
    padding: $spacing-section 0;

    + section:not([class*="bg-color"]) {
        padding-top: 0;
    }

    &[class*="bg-color"] {
        padding: calc($spacing-section * 2 / 3) 0;

        + section:not([class*="bg-color"]) {
            padding-top: $spacing-section;
        }
    }

    main & {
        scroll-margin-top: 48px;
    }
}

@include from ($mobile-menu-breakpoint) {
    section {
        padding: 60px 0;
    
        + section:not([class*="bg-color"]) {
            padding-top: 0;
        }
    
        &[class*="bg-color"] {
            padding: calc($spacing-section * 4 / 3) 0;
    
            + section:not([class*="bg-color"]) {
                padding-top: calc($spacing-section * 2);
            }
        }

        main & {
            scroll-margin-top: 78px;
        }
    }
}