@use '../../../css' as *;

.module-info {
    .box {
        padding-top: 35px;
        padding-bottom: 25px;
    }

    strong:nth-of-type(2) {
        color: $color-brand-primary;
    }

    a {
        &:hover,
        &:focus,
        &:active {
            color: $color-brand-primary;
        }
    }
}