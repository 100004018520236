@use '../../../css' as *;

.module {
    .column {
        display: flex;
        flex-wrap: wrap;
        gap: $gap;

        .box {
            flex-basis: 100%;

            + .box {
                margin-top: 0;
            }
        }
    }
}