@use '../../../css' as *;

.hero {
    overflow: hidden;
    position: relative;
    z-index: 10;

    figure {
        position: relative;
        z-index: 10;

        img {
            display: block;
        }
    }

    aside {
        background-color: $color-brand-primary;
        color: $color-text-inverted;
        height: 48px;
        position: relative;
        z-index: 20;

        .wd-feed &       { background-color: $color-wd-feed; }
        .wd-food &       { background-color: $color-wd-food; }
        .wd-pharma-api & { background-color: $color-wd-pharma-api; }
        .wd-pharma-fdf & { background-color: $color-wd-pharma-fdf; }

        .container {
            position: relative;
            height: 100%;
        }

        .logo {
            background: url("../../#{$shape-triangle-rounded-top-white}") no-repeat bottom center;
            bottom: -1px;
            height: 75px;
            position: absolute;
            right: 0;
            width: 160px;

            // logo
            &:before {
                background: transparent url($logo-wd-group-75) no-repeat center bottom;
                bottom: 8px;
                content: "";
                display: block;
                left: 0;
                margin: 0 auto;
                position: absolute;
                right: 0;
                width: 55%;
                height: 25%;

                .wd-feed &       { background-image: url($logo-wd-feed); }
                .wd-food &       { background-image: url($logo-wd-food); }
                .wd-pharma-api & { background-image: url($logo-wd-pharma-api); }
                .wd-pharma-fdf & { background-image: url($logo-wd-pharma-fdf); }
            }
        }

        .nav-hero {
            height: 100%;

            ul {
                display: flex;
                flex-wrap: wrap;
                height: 100%;
                align-items: center;
                margin-right: 130px;

                li {

                    &:nth-child(2) {
                        a { width: 100%; }
                    }

                    a {
                        color: $color-text-inverted;
                        display: flex;
                        align-items: center;
                        padding: 14px 0;

                        // icons
                        &:before {
                            background: transparent none no-repeat left center;
                            background-size: contain;
                            content: "";
                            display: inline-block;
                            height: 20px;
                            width: 20px;
                        }

                        &:not([href^="tel"]) {
                            span { @include sr-only; }
                        }

                        &[href^="tel"] {
                            &:before { background-image: url("#{$icon-headset-white}"); }

                            span { display: none; }
                        }

                        &[href^="mailto"]:before    { background-image: url("#{$icon-envelope-white}"); width: 23px; }
                        &[href*="whatsapp"]:before  { background-image: url("#{$icon-whatsapp-white}"); }
                        &[href*="linkedin"]:before  { background-image: url("#{$icon-linkedin-white}"); }
                        &[href*="xing"]:before      { background-image: url("#{$icon-xing-white}"); }
                    }

                    &:not(:last-child) {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

@include from($tablet) {
    .hero aside {
        height: 62px;

        .logo {
            height: 180px;
            width: 385px;
        }

        .nav-hero {
            flex-wrap: nowrap;
            margin-right: 0;

            ul {
                li {
                    a {
                        padding: 0;

                        // icons
                        &:before {
                            height: 16px;
                            width: 16px;
                        }

                        &:not([href^="tel"]) {
                            &:hover,
                            &:focus,
                            &:active {
                                transform: scale(1.7);
                            }
                        }

                        &[href^="tel"] {
                            span {
                                display: block;
                                margin: 0 32px 0 8px;
                            }

                            &:hover,
                            &:focus,
                            &:active {
                                text-decoration: underline;

                                &:before {
                                    transform: scale(1.5);
                                    transition: all 0.2s ease;
                                }
                            }
                        }

                        &[href^="mailto:"] {
                            &:hover,
                            &:focus,
                            &:active {
                                transform: scale(1.4);
                            }
                        }
                    }
                }
            }
        }
    }
}
