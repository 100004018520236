@use "variables.scss" as *;

$container-max-width: 960px !default;

.container {
  flex-grow: 1;
  margin: 0 auto;
  padding: 0 $gap;
  position: relative;
  max-width: $container-max-width;
  width: calc(100% - #{$gap} * 2);

  &.is-fluid {
    max-width: 1300px !important;
    padding-left: $gap;
    padding-right: $gap;
    width: calc(100% - #{$gap} * 2);

    > .columns {
      width: 100%;
      margin: 0;
    }
  }
}