@use 'sass:math';
@use "mixins.scss" as *;


$column-gap: 8px !default;

.column {
  box-sizing: border-box;
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: $column-gap;

  .columns.is-mobile > &.is-narrow {
    flex: none;
    width: unset;
  }

  .columns.is-mobile > &.is-full {
    flex: none;
    width: 100%;
  }

  @for $i from 0 through 12 {
    .columns.is-mobile > &.is-#{$i} {
      flex: none;
      width: math.percentage(math.div($i, 12));
    }

    .columns.is-mobile > &.is-offset-#{$i} {
      margin-left: math.percentage(math.div($i, 12));
    }
  }

  @include mobile {
    &.is-narrow-mobile {
      flex: none;
      width: unset;
    }

    &.is-full-mobile {
      flex: none;
      width: 100%;
    }

    @for $i from 0 through 12 {
      &.is-#{$i}-mobile {
        flex: none;
        width: math.percentage(math.div($i, 12));
      }

      &.is-offset-#{$i}-mobile {
        margin-left: math.percentage(math.div($i, 12));
      }
    }
  }

  @include mobile-small {
    &.is-narrow-mobile-small {
      flex: none;
      width: unset;
    }

    &.is-full-mobile-small {
      flex: none;
      width: 100%;
    }

    @for $i from 0 through 12 {
      &.is-#{$i}-mobile-small {
        flex: none;
        width: math.percentage(math.div($i, 12));
      }

      &.is-offset-#{$i}-mobile-small {
        margin-left: math.percentage(math.div($i, 12));
      }
    }
  }

  @include tablet {
    &.is-narrow,
    &.is-narrow-tablet {
      flex: none;
      width: unset;
    }

    &.is-full,
    &.is-full-tablet {
      flex: none;
      width: 100%;
    }

    @for $i from 0 through 12 {
      &.is-#{$i},
      &.is-#{$i}-tablet {
        flex: none;
        width: math.percentage(math.div($i, 12));
      }

      &.is-offset-#{$i},
      &.is-offset-#{$i}-tablet {
        margin-left: math.percentage(math.div($i, 12));
      }
    }
  }


  @include touch {
    &.is-narrow-touch {
      flex: none;
      width: unset;
    }

    &.is-full-touch {
      flex: none;
      width: 100%;
    }

    @for $i from 0 through 12 {
      &.is-#{$i}-touch {
        flex: none;
        width: math.percentage(math.div($i, 12));
      }

      &.is-offset-#{$i}-touch {
        margin-left: math.percentage(math.div($i, 12));
      }
    }
  }


  @include desktop {
    &.is-narrow-desktop {
      flex: none;
      width: unset;
    }

    &.is-full-desktop {
      flex: none;
      width: 100%;
    }

    @for $i from 0 through 12 {
      &.is-#{$i}-desktop {
        flex: none;
        width: math.percentage(math.div($i, 12));
      }

      &.is-offset-#{$i}-desktop {
        margin-left: math.percentage(math.div($i, 12));
      }
    }
  }


  @include widescreen {
    &.is-narrow-widescreen {
      flex: none;
      width: unset;
    }

    &.is-full-widescreen {
      flex: none;
      width: 100%;
    }

    @for $i from 0 through 12 {
      &.is-#{$i}-widescreen {
        flex: none;
        width: math.percentage(math.div($i, 12));
      }

      &.is-offset-#{$i}-widescreen {
        margin-left: math.percentage(math.div($i, 12));
      }
    }
  }


  @include fullhd {
    &.is-narrow-fullhd {
      flex: none;
      width: unset;
    }

    &.is-full-fullhd {
      flex: none;
      width: 100%;
    }

    @for $i from 0 through 12 {
      &.is-#{$i}-fullhd {
        flex: none;
        width: math.percentage(math.div($i, 12));
      }

      &.is-offset-#{$i}-fullhd {
        margin-left: math.percentage(math.div($i, 12));
      }
    }
  }
}

.columns {
  margin-left: -$column-gap;
  margin-right: -$column-gap;
  margin-top: -$column-gap;
  flex-wrap: wrap;
  width: calc(100% + 2 * $column-gap);

  &:last-child {
    margin-bottom: -$column-gap;
  }

  &:not(:last-child) {
    margin-bottom: calc(1.5rem - #{$column-gap});
  }

  // Modifiers
  &.is-centered {
    justify-content: center;
  }

  &.is-gapless {
    margin-left: 0;
    margin-right: 0;

    margin-top: 0;

    & > .column {
      margin: 0;
      padding: 0 !important;
    }

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.is-mobile {
    display: flex;
  }

  &.is-multiline {
    flex-wrap: wrap;
  }

  &.is-vcentered {
    align-items: center;
  }

  // Responsiveness
  @include tablet {
    &:not(.is-desktop) {
      display: flex;
    }
  }


  @include desktop {
    // Modifiers
    &.is-desktop {
      display: flex;
    }
  }
}