@use "variables.scss" as *;
@use "mixins.scss" as *;

// show text for screenreaders only

.sr-only {
    @include sr-only;
}

.bg-color-auto {
    background-color: $color-brand-primary;

    .wd-feed &       { background-color: $color-wd-feed; }
    .wd-food &       { background-color: $color-wd-food; }
    .wd-pharma-api & { background-color: $color-wd-pharma-api; }
    .wd-pharma-fdf & { background-color: $color-wd-pharma-fdf; }
}

.bg-color-wd-group       { background-color: $color-brand-primary; }
.bg-color-wd-pharma-api  { background-color: $color-wd-pharma-api; }
.bg-color-wd-pharma-fdf  { background-color: $color-wd-pharma-fdf; }
.bg-color-wd-food        { background-color: $color-wd-food; }
.bg-color-wd-feed        { background-color: $color-wd-feed; }
.bg-color-grey           { background-color: $grey-base; }


.color-brand {
    color: $color-brand-primary;

    .wd-feed &       { color: $color-wd-feed; }
    .wd-food &       { color: $color-wd-food; }
    .wd-pharma-api & { color: $color-wd-pharma-api; }
    .wd-pharma-fdf & { color: $color-wd-pharma-fdf; }
}

.color-wd-group          { color: $color-brand-primary; }
.color-wd-pharma-api     { color: $color-wd-pharma-api; }
.color-wd-pharma-fdf     { color: $color-wd-pharma-fdf; }
.color-wd-food           { color: $color-wd-food; }
.color-wd-feed           { color: $color-wd-feed; }
.color-grey              { color: $grey-base; }