@use '../../../css' as *;

.module-contact {
    .column {

        // height + height when entire box is linked
        .box { height: auto; }
        a:not(:only-child) > .box { height: 100%; }

        // when entire box is linked
        > a {
            width: 100%;
            transition: none;

            &:hover,
            &:focus,
            &:active {
                .box {
                    transform: scale(1.05);
                }

                .box, .link {
                    color: $color-brand-primary;

                    .wd-feed &       { color: $color-wd-feed; }
                    .wd-food &       { color: $color-wd-food; }
                    .wd-pharma-api & { color: $color-wd-pharma-api; }
                    .wd-pharma-fdf & { color: $color-wd-pharma-fdf; }
                }
            }
        }

        // multiple boxes in column
        .box:not(:only-child) > :first-child,
        a:not(:only-child) > .box > :first-child {
            text-indent: 20px;
        }

        // one box in column
        > .box:only-child,
        > a:only-child > .box {
            padding: 24px;
        }
    }

    a[href^="tel:"] {
        color: $color-brand-primary;
        font-weight: bold;

        .wd-feed &       { color: $color-wd-feed; }
        .wd-food &       { color: $color-wd-food; }
        .wd-pharma-api & { color: $color-wd-pharma-api; }
        .wd-pharma-fdf & { color: $color-wd-pharma-fdf; }
    }

    .icon {
        fill: $color-brand-primary;

        .wd-feed &       { fill: $color-wd-feed; }
        .wd-food &       { fill: $color-wd-food; }
        .wd-pharma-api & { fill: $color-wd-pharma-api; }
        .wd-pharma-fdf & { fill: $color-wd-pharma-fdf; }
    }

    .icon-envelope {
        margin-right: 15px;
        height: 32px;
        width: 46px;
    }

    .icon-headset {
        height: 35px;
        width: 32px;
    }

    .icon-link {
        display: inline-block;

        svg {
            display: block;
        }

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;

            svg {
                transform: scale(1.3);
            }
        }
    }

    .icon-link-social {
        svg.icon {
            fill: $grey-50;
            height: 30px;
            width: 30px;
        }

        &:hover,
        &:focus,
        &:active {
            transform: scale(1.15);

            svg {
                fill: $color-brand-primary;

                .wd-feed &       { fill: $color-wd-feed; }
                .wd-food &       { fill: $color-wd-food; }
                .wd-pharma-api & { fill: $color-wd-pharma-api; }
                .wd-pharma-fdf & { fill: $color-wd-pharma-fdf; }
            }
        }
            
        &:not(:last-child) {
            margin-right: 5px;
        }
    }

    button {
        box-shadow: 0 0 10px hsl(0deg 0% 40%);
    }
}