@use "mixins.scss" as *;
@use "variables.scss" as *;

strong  { font-weight: bold; }
em      { font-style: italic; }


// ---------- HEADLINES ----------

h1, *.h1,
h2, *.h2,
h3, *.h3,
h4, *.h4,
h5, *.h5,
h6, *.h6 {
    color: $color-headline-base;
    font-weight: bold;
}

.container,
.column {
    > {
        h1, *.h1,
        h2, *.h2,
        h3, *.h3,
        h4, *.h4,
        h5, *.h5,
        h6, *.h6 {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@include from($mobile-menu-breakpoint) {
    .container,
    .column {
        > {
            h1, *.h1,
            h2, *.h2,
            h3, *.h3,
            h4, *.h4,
            h5, *.h5,
            h6, *.h6 {
                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}

h1, *.h1,
h2, *.h2 {
    font-size: $font-size-h1-mobile;
    line-height: $line-height-h1;
    margin-bottom: 1.6rem;
    margin-top: 1.5rem;
    text-transform: initial;
    word-break: break-word;

    .wd-feed &       { color: $color-text; }
    .wd-food &       { color: $color-text; }
    .wd-pharma-api & { color: $color-text; }
    .wd-pharma-fdf & { color: $color-text; }
}

h3, *.h3 {
    color: $color-h3;
    font-size: $font-size-h3;
    font-weight: 500;
    line-height: $line-height-h3;
    margin: 1rem 0;
    text-transform: uppercase;

    .column > &:only-child {
        margin-bottom: 0;
    }

    .wd-feed &       { color: $color-h3; }
    .wd-food &       { color: $color-h3; }
    .wd-pharma-api & { color: $color-h3; }
    .wd-pharma-fdf & { color: $color-h3; }
}

h4, *.h4 {
    color: $color-h4;
    font-size: $font-size-h4;
    font-weight: 500;
    line-height: $line-height-h4;
    margin: 1.5rem 0;
    text-transform: uppercase;

    .wd-feed &       { color: $color-wd-feed; }
    .wd-food &       { color: $color-wd-food; }
    .wd-pharma-api & { color: $color-wd-pharma-api; }
    .wd-pharma-fdf & { color: $color-wd-pharma-fdf; }
}

h5, *.h5 {
    font-size: $font-size-h5;
    line-height: $line-height-h5;
    
    .wd-feed &       { color: $color-text; }
    .wd-food &       { color: $color-text; }
    .wd-pharma-api & { color: $color-text; }
    .wd-pharma-fdf & { color: $color-text; }
}


// desktop
@media (min-width: 768px) {
    h1, *.h1,
    h2, *.h2  { font-size: $font-size-h1; }
}


// ---------- TEXT ----------

.intro {
    font-size: $font-size-text-intro;
    line-height: $line-height-text-intro;

    p:last-child {
        margin-bottom: 0;
    }
}

p {
    margin-bottom: 1rem;

    .column > &:first-child {
        margin-top: 0;
    }
}