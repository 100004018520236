@use '../../../css' as *;

.teaser-event {
    padding-bottom: 15px;

    > {
        img,
        figure,
        picture {
            &:first-child {
                margin: 0 0 $gap;
                width: 100%;
            }
        }
    }

    .title {
        color: $grey-50;
        font-size: $font-size-text;
        font-weight: 500;
        line-height: $line-height-text;
        margin-bottom: 0;
        text-transform: uppercase;
    }

    .date,
    .location {
        align-items: flex-start;
        display: flex;
        flex-wrap: nowrap;
        margin: 16px 0 0;
    }

    .date .icon {
        flex-shrink: 0;
        flex-grow: 0;
        height: 23px;
        margin-right: calc(36px - 26px);
        margin-top: -5px;
        width: 26px;
    }

    .location .icon {
        height: 27px;
        margin-left: 3px;
        margin-right: calc(36px - 18px - 3px);
        margin-top: -5px;
        width: 18px;
    }

    .from,
    .until {
        white-space: nowrap;
    }
}