@use '../../../css' as *;

.image-collage {
    display: block;

    // aspect ratio 1:1
    padding-bottom: 100%;
    position: relative;
    width: 100%;

    // mask
    .mask {
        mask-repeat: no-repeat;
        mask-size: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        position: absolute;

        img {
            object-fit: cover;
            object-position: left center;
            height: 100%;
            width: 100%;
        }
    }

    // shape
    &:before {
        background: transparent no-repeat 0 0;
        content: "";
        display: block;
        mix-blend-mode: multiply;
        position: absolute;
        width: 25%;
        height: 42%;
        z-index: 1;
    }

    // all shapes and masks
    &.image-collage-top-right {
        .mask { mask-image: url("../../#{$mask-img-top-right}"); }
        &:before {
            top: 7%;
            right: 10%;
        }
    }

    &.image-collage-bottom-right {
        .mask { mask-image: url("../../#{$mask-img-bottom-right}"); }
        &:before {
            bottom: 7%;
            right: 10%;
        }
    }

    &.image-collage-top-right,
    &.image-collage-bottom-right {

        &:before {
            background-image: url("../../#{$shapes-folder}shape-triangle-#{$brand-slug-wd-group}-right.svg");

            .wd-feed &       { background-image: url("../../#{$shapes-folder}shape-triangle-#{$brand-slug-wd-feed}-right.svg"); }
            .wd-food &       { background-image: url("../../#{$shapes-folder}shape-triangle-#{$brand-slug-wd-food}-right.svg"); }
            .wd-pharma-api & { background-image: url("../../#{$shapes-folder}shape-triangle-#{$brand-slug-wd-pharma-api}-right.svg"); }
            .wd-pharma-fdf & { background-image: url("../../#{$shapes-folder}shape-triangle-#{$brand-slug-wd-pharma-fdf}-right.svg"); }
        }
    }

    &.image-collage-top-left {
        .mask { mask-image: url("../../#{$mask-img-top-left}"); }
        .mask img { object-position: right center; }
        &:before {
            top: 7%;
            left: 10%;

        }
    }

    &.image-collage-bottom-left {
        .mask { mask-image: url("../../#{$mask-img-bottom-left}"); }
        .mask img { object-position: right center; }
        &:before {
            bottom: 7%;
            left: 10%;
        }
    }

    &.image-collage-top-left,
    &.image-collage-bottom-left {
    	margin-top: $spacing-section;

        @include from ($mobile-menu-breakpoint) {
    	    margin-top: 0;
        }

        &:before {
            background-image: url("../../#{$shapes-folder}shape-triangle-#{$brand-slug-wd-group}-left.svg");

            .wd-feed &       { background-image: url("../../#{$shapes-folder}shape-triangle-#{$brand-slug-wd-feed}-left.svg"); }
            .wd-food &       { background-image: url("../../#{$shapes-folder}shape-triangle-#{$brand-slug-wd-food}-left.svg"); }
            .wd-pharma-api & { background-image: url("../../#{$shapes-folder}shape-triangle-#{$brand-slug-wd-pharma-api}-left.svg"); }
            .wd-pharma-fdf & { background-image: url("../../#{$shapes-folder}shape-triangle-#{$brand-slug-wd-pharma-fdf}-left.svg"); }
        }
    }
}
