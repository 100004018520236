@use '../../../css' as *;

.teaser-logo {
    padding-bottom: 35px;

    figure,
    picture {
        position: relative;
        margin-bottom: 30px;

        img {
            display: block;
        }

        // triangle shape
        &:before {
            background: url(../../../../public/img/shapes/shape-triangle-rounded-top-white.svg) no-repeat bottom center;
            bottom: -1px;
            content: "";
            display: block;
            height: 100%;
            position: absolute;
            right: -7%;
            width: 65%;
        }

        // logo
        &:after {
            background: transparent url(../../../../public/img/logos-sub-brands/logo-welding-group.svg) no-repeat center bottom;
            bottom: 11px;
            content: "";
            display: block;
            margin: 0 auto;
            position: absolute;
            right: 6%;
            width: 40%;
            height: 25%;
        }
    }

    // logos of sub brands
    &.wd-feed        { figure, picture { &:after { background-image: url(#{$logo-wd-feed}); } } }
    &.wd-food        { figure, picture { &:after { background-image: url(#{$logo-wd-food}); } } }
    &.wd-pharma-api  { figure, picture { &:after { background-image: url(#{$logo-wd-pharma-api}); } } }
    &.wd-pharma-fdf  { figure, picture { &:after { background-image: url(#{$logo-wd-pharma-fdf}); } } }
}