// FONTS

$fonts-folder: '../../public/fonts/jost/';


@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    src: local(''),
         url($fonts-folder+'jost-v13-latin-regular.woff2') format('woff2'),
         url($fonts-folder+'jost-v13-latin-regular.woff') format('woff');
  }

  @font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    src: local(''),
         url($fonts-folder+'jost-v13-latin-500.woff2') format('woff2'),
         url($fonts-folder+'jost-v13-latin-500.woff') format('woff');
  }
  
  @font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 700;
    src: local(''),
         url($fonts-folder+'jost-v13-latin-700.woff2') format('woff2'),
         url($fonts-folder+'jost-v13-latin-700.woff') format('woff');
  }
  
  @font-face {
    font-family: 'Jost';
    font-style: italic;
    font-weight: 400;
    src: local(''),
         url($fonts-folder+'jost-v13-latin-italic.woff2') format('woff2'),
         url($fonts-folder+'jost-v13-latin-italic.woff') format('woff');
  }
  
  @font-face {
    font-family: 'Jost';
    font-style: italic;
    font-weight: 500;
    src: local(''),
         url($fonts-folder+'jost-v13-latin-500italic.woff2') format('woff2'),
         url($fonts-folder+'jost-v13-latin-500italic.woff') format('woff');
  }
  
  @font-face {
    font-family: 'Jost';
    font-style: italic;
    font-weight: 700;
    src: local(''),
         url($fonts-folder+'jost-v13-latin-700italic.woff2') format('woff2'),
         url($fonts-folder+'jost-v13-latin-700italic.woff') format('woff');
  }