@use '../../../css' as *;

.module-numbers {
    .column {
        .box {
            display: flex;
            height: auto;

            .icon {
                margin: 0 14px 14px 0;
            }
        }

        .box {
            padding: 20px 15px 12px;

            .icon {
                height: 50px;
                width: 50px;
                flex-shrink: 0;
            }

            .number,
            .number-label {
                font-size: $font-size-numbers;
                line-height: $line-height-numbers;
            }
        }

        @include from($tablet) {

            // when one box in column
            .box:only-child {
                align-content: space-between;
                flex-wrap: wrap;
                padding: 24px;

                .icon {
                    height: 75px;
                    width: 75px;
                }

                .number,
                .number-label {
                    font-size: $font-size-h2;
                    line-height: $line-height-h2;
                }
            }
        }
    }

    svg.icon {
        fill: transparent;
        stroke: $color-brand-primary;
        stroke-width: 1px;
    }

    .number {
        color: $color-brand-primary;
        font-weight: bold;
    }
}