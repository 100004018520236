@use "../../../css/variables.scss" as *;
@use "../../../css/mixins.scss" as *;

.map {
    padding: 0 0 100%;
    overflow: hidden;
    position: relative;
    width: 100%;

    @include from ($tablet) {
        padding-bottom: 68.75%;
        border-top-left-radius: 40px;
    }

    iframe {
        position: absolute;
        height: 100%;
        width: 100%;


        @include from ($tablet) {
            border-top-left-radius: 40px;
        }
    }
}