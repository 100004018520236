@use '../../../css' as *;

// boxes general
.box {
  background-color: $white;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  position: relative;

  :first-child {
      margin-top: 0;
  }

  :last-child {
      margin-bottom: 0;
  }

  &.bg-color-auto {
    background-color: $color-brand-primary;
    color: $white;

    .wd-feed &       { background-color: $color-wd-feed; }
    .wd-food &       { background-color: $color-wd-food; }
    .wd-pharma-api & { background-color: $color-wd-pharma-api; }
    .wd-pharma-fdf & { background-color: $color-wd-pharma-fdf; }
  }
}

.box-grey {
  background-color: $grey-base;
}

// box in text
.box-textbox {
  height: auto;
  padding: 20px;
}


// linked boxes
a {
  &:hover,
  &:focus,
  &:active {
      > .box {
          transform: scale(1.05);
          z-index: 10;
      }
  }
}

// box w rounded corners
.box-rounded {
  box-shadow: $box-shadow-base;
  border-radius: $border-radius-small;
  padding: 20px 20px 35px;

  > a {
      display: block;
      padding: 20px;
      margin: -20px;
      font-style: normal;
      text-decoration: none;

      &:hover,
      &:focus,
      &:active {
          color: inherit;
      }
  }

  > {
      img,
      figure,
      picture {
          display: block;

          &:first-child {
              margin: -20px -20px 0;
              width: calc(100% + 40px);
              height: auto;
          }

          + * {
              margin-top: 30px;
          }
      }
  }

  h5 {
      margin-bottom: 0;
  }
}


// box w rounded corner top left

.box-rounded-corner-tl {
  border-top-left-radius: $border-radius-big;
  padding: 20px 10px 10px;

  & + & { margin-top: 10px; }

  > :first-child {
      margin-top: 0;
      padding-top: 0;
  }

  > :last-child {
      margin-bottom: 0;
      padding-bottom: 0;
  }
}
