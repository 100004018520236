@use "variables.scss" as *;


// ---------- TEXT FOR SCREENREADERS ONLY ----------

@mixin sr-only {
    border: 0;
    clip-path: inset(50%);
    clip: rect(0,0,0,0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}


// ----------- RESPONSIVENESS ----------

@mixin from($device) {
    @media screen and (min-width: $device) {
        @content;
    }
}

@mixin until($device) {
    @media screen and (max-width: ($device - 1px)) { @content; }
}
  
@mixin between($from, $until) {
    @media screen and (min-width: $from) and (max-width: ($until - 1px)) { @content; }
}

@mixin mobile-small {
  @media screen and (max-width: ($mobile-big - 1px)) { @content; }
}

@mixin mobile {
    @media screen and (max-width: ($tablet - 1px)) { @content; }
}
  
@mixin tablet {
    @media screen and (min-width: $tablet), print { @content; }
}
  
@mixin tablet-only {
    @media screen and (min-width: $tablet) and (max-width: ($desktop - 1px)) { @content; }
}
  
@mixin touch {
    @media screen and (max-width: ($desktop - 1px)) { @content; }
}
  
@mixin desktop {
    @media screen and (min-width: $desktop) { @content; }
}
  
@mixin desktop-only {
    @if $widescreen-enabled {
      @media screen and (min-width: $desktop) and (max-width: ($widescreen - 1px)) {
        @content;
      }
    }
  }
  
@mixin until-widescreen {
    @if $widescreen-enabled {
      @media screen and (max-width: ($widescreen - 1px)) {
        @content;
      }
    }
}
  
@mixin widescreen {
    @if $widescreen-enabled {
      @media screen and (min-width: $widescreen) {
        @content;
      }
    }
}
  
@mixin widescreen-only {
    @if $widescreen-enabled and $fullhd-enabled {
      @media screen and (min-width: $widescreen) and (max-width: ($fullhd - 1px)) {
        @content;
      }
    }
}
  
@mixin until-fullhd {
    @if $fullhd-enabled {
      @media screen and (max-width: ($fullhd - 1px)) {
        @content;
      }
    }
}
  
@mixin fullhd {
    @if $fullhd-enabled {
      @media screen and (min-width: $fullhd) {
        @content;
      }
    }
}
  
@mixin breakpoint($name) {
    $breakpoint: map-get($breakpoints, $name);
  
    @if $breakpoint {
      $from: map-get($breakpoint, "from");
      $until: map-get($breakpoint, "until");
  
      @if $from and $until {
        @include between($from, $until) {
          @content;
        }
      }
      @else if $from {
        @include from($from) {
          @content;
        }
      }
      @else if $until {
        @include until($until) {
          @content;
        }
      }
    }
}

@mixin auto-color {
  color: $color-wd-group;
  
  .wd-feed &       { color: $color-wd-feed; }
  .wd-food &       { color: $color-wd-food; }
  .wd-pharma-api & { color: $color-wd-pharma-api; }
  .wd-pharma-fdf & { color: $color-wd-pharma-fdf; }
}