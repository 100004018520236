@use '../../../css' as *;

.teaser-news {

    > {
        img,
        figure,
        picture {
            &:first-child {
                margin: 0;
                width: 100%;
            }
        }
    }

    .meta {
        color: $color-text;
        margin: 0.5rem 0 1rem;
    }

    .category {
        font-weight: bold;
    }

    .title {
        color: $grey-50;
        display: block;
        font-size: $font-size-text;
        font-weight: 500;
        line-height: $line-height-text;
        margin-bottom: 0;
        text-transform: uppercase;
    }

    .meta {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
    }


    &.wd-group .category         { color: $grey-50; }
    &.wd-food  .category         { color: $color-wd-food; }
    &.wd-feed  .category         { color: $color-wd-feed; }
    &.wd-pharma-api .category    { color: $color-wd-pharma-api; }
    &.wd-pharma-fdf .category    { color: $color-wd-pharma-fdf; }
}
