@use "variables.scss" as *;

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;
}

body {
  background-color: $white;
  color: $color-text;
  font-family: $font-family-base;
  font-size: 1rem;
  line-height: $line-height-base;
  margin: 0;
  text-size-adjust: none;

  > * {
    font-size: $font-size-base;
  }
}

* {
  transition: 0.2s ease;
  transition-property: background-color, transform, height, width;
}
