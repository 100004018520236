@use '../../../css' as *;

a {
    &:hover,
    &:focus,
    &:active {
        > .box[class*="teaser"] {
            .title,
            .link {
                @include auto-color;
            }

            > .box[class*="teaser"]:not(.teaser-news) {
                &.wd-group {
                    .title,
                    .link {color: $color-wd-group; }
                }
                &.wd-food {
                    .title,
                    .link { color: $color-wd-food; }
                }
                &.wd-feed {
                    .title,
                    .link { color: $color-wd-feed; }
                }
                &.wd-pharma-api {
                    .title,
                    .link { color: $color-wd-pharma-api; }
                }
                &.wd-pharma-fdf {
                    .title,
                    .link { color: $color-wd-pharma-fdf; }
                }
            }
        }
    }
}