@use '../../../css' as *;

button,
.button,
input[type="submit"] {
    border: none;
    font-family: $font-family-form;
    font-size: $font-size-base;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    cursor: pointer;
}

main {
    button,
    .button,
    input[type="submit"] {
        background-color: $bg-color-button;
        box-shadow: $box-shadow-button;
        box-sizing: border-box;
        border-radius: 20px;
        color: $color-button;
        display: block;
        height: 40px;
        margin: 0 auto;
        padding: 9px 30px 9px 48px;
        width: auto;
        position: relative;
        @include auto-color;

        &:before {
            background: transparent url("#{$icon-arrow-right}") no-repeat center center;
            background-size: contain;
            content: "";
            display: block;
            left: 26px;
            position: absolute;
            top: 0;
            width: 14px;
            height: 100%;
        }

        &:hover,
        &:focus,
        &:active {
            color: $color-button-hover;
            transform: scale(1.15);

            .wd-feed &       { color: $color-wd-feed-hover; }
            .wd-food &       { color: $color-wd-food-hover; }
            .wd-pharma-api & { color: $color-wd-pharma-api-hover; }
            .wd-pharma-fdf & { color: $color-wd-pharma-fdf-hover; }
        }
    }

    p, ul, ol {
        + {
            button,
            .button,
            input[type="submit"] {
                margin-top: 1.5rem;
            }
        }
    }
}

main {
    input[type="submit"] {
        background: transparent url("#{$icon-arrow-right}") no-repeat 40px center;
        background-size: 14px 10px;
        padding-left: 65px;
        padding-right: 45px;

        &:not(:first-child) {
            margin-top: $gap;
        }
    }
}