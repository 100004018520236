@use "variables.scss" as *;
@use "mixins.scss" as *;

.form-search {
    display: flex;
    height: 48px;
    margin: 0 $gap;
    position: relative;
    width: auto;

    button {
        background-color: $bg-color-form-elements;
        height: 38px;
        padding: 0;
        position: relative;
        width: 38px;

        svg {
            bottom: 0;
            position: absolute;
            right: 10px;
            top: 10px;
        }

        &:hover,
        &:focus,
        &:active {
            svg {
                fill: $color-brand-primary;
            }
        }
    }
}

input[type="search"] {
    background-color: $bg-color-form-elements;
    border-radius: 0;
    border: none;
    box-sizing: border-box;
    color: $color-text;
    font-family: $font-family-base;
    font-size: $font-size-header;
    height: 38px;
    line-height: $line-height-base;
    min-height: 0;
    padding: 0 0 0 6px;
    width: 100%;
}

@include from ($mobile-menu-breakpoint) {
    .form-search {
        margin: 0;
        height: auto;

        button {
            background-color: transparent;
            height: 24px;
            order: 1;
            width: 24px;

            svg {
                top: 3px;
                right: 7px;
            }
        }
    }

    input[type="search"] {
        height: 24px;
        order: 2;
        padding: 0 0 0 6px;
        width: 106px;
    }
}