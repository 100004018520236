@use 'functions' as *;

// ---------- BRAND COLOURS + LOGO PATH ----------

$logo-folder:                 '../../../../public/img/logos-sub-brands/';

// Welding Group
$color-wd-group:              #e42313;
$color-wd-group-hover:        darken($color-wd-group, 20%);
$logo-wd-group:               $logo-folder+'logo-welding-group.svg';
$logo-wd-group-75:            $logo-folder+'logo-welding-group-75.svg';
$brand-slug-wd-group:         'wd-group';

// Welding Food
$color-wd-food:               #8ab439;
$color-wd-food-hover:         darken($color-wd-food, 20%);
$logo-wd-food:                $logo-folder+'logo-welding-food.svg';
$brand-slug-wd-food:          'wd-food';

// Welding Feed
$color-wd-feed:               #00957a;
$color-wd-feed-hover:         darken($color-wd-feed, 10%);
$logo-wd-feed:                $logo-folder+'logo-welding-feed.svg';
$brand-slug-wd-feed:          'wd-feed';

// Welding Pharma API
$color-wd-pharma-api:         #00a4d9;
$color-wd-pharma-api-hover:   darken($color-wd-pharma-api, 20%);
$logo-wd-pharma-api:          $logo-folder+'logo-welding-pharma-api.svg';
$brand-slug-wd-pharma-api:    'wd-pharma-api';

// Welding Pharma FDF
$color-wd-pharma-fdf:         #0065a3;
$color-wd-pharma-fdf-hover:   darken($color-wd-pharma-fdf, 20%);
$logo-wd-pharma-fdf:          $logo-folder+'logo-welding-pharma-fdf.svg';
$brand-slug-wd-pharma-fdf:    'wd-pharma-fdf';


:root {
    --color-brand-primary:     #e42313;
    --color-wd-group:          var(--color-brand-primary);
    --color-wd-food:           #8ab439;
    --color-wd-feed:           #00957a;
    --color-wd-pharma-api:     #00a4d9;
    --color-wd-pharma-fdf:     #0065a3;
    --color-link:              #666666;
    --color-grey-base:         #f2f2f2;
    --color-grey-50:           #808080;
    --color-text:              #1a1a1a;
}

// ---------- COLORS ----------

$color-brand-primary:           $color-wd-group;
$color-brand-primary-hover:     darken($color-brand-primary, 20%);

$black:                         #000;
$white:                         #fff;
$red:                           $color-brand-primary;

$grey-5:                        tint($black, 5%);      // #f2f2f2
$grey-10:                       tint($black, 10%);     // #e5e5e5
$grey-20:                       tint($black, 20%);
$grey-30:                       tint($black, 30%);
$grey-40:                       tint($black, 40%);
$grey-50:                       tint($black, 50%);     // #808080
$grey-60:                       tint($black, 60%);     // #666666
$grey-70:                       tint($black, 70%);
$grey-80:                       tint($black, 80%);     // #333
$grey-90:                       tint($black, 90%);     // #1a1a1a

$grey-base:                     $grey-5;

$color-text:                    $grey-90;
$color-text-inverted:           $white;
$color-text-intro:              $grey-80;
$color-link:                    $grey-60;
$color-link-hover:              $color-brand-primary;

$color-button:                  $color-brand-primary;
$color-button-hover:            $color-brand-primary-hover;

$color-nav-main:                $color-text;
$color-nav-main-hover:          $color-brand-primary;
$color-nav-main-current:        $color-text;
$color-nav-main-current-mobile: $color-brand-primary;
$color-nav-main-sub:            $color-text;
$color-nav-main-sub-hover:      $color-nav-main-hover;
$color-nav-main-sub-current:    $color-nav-main-hover;
$color-nav-lang-hover:          $color-brand-primary;
$color-nav-lang-current:        $color-brand-primary;


$color-headline-base:           $color-text;
$color-h1:                      $color-headline-base;
$color-h2:                      $color-headline-base;
$color-h3:                      $grey-50;
$color-h4:                      $color-brand-primary;
$color-h5:                      $color-headline-base;

$bg-color-header:               $white;
$bg-color-footer:               $grey-10;
$bg-color-form-elements:        $grey-base;
$bg-color-button:               $white;

$border-color-nav-main:         $grey-10;
$border-color-form-focus:       $grey-40;


// ---------- FONTS ----------

$font-family-base:              'Jost', 'Helvetica Neue', Helvetica, Arial;
$font-family-form:              $font-family-base;


// ---------- FONT SIZES ----------

$font-size-base:                0.875rem;           // 14px
$font-size-text:                $font-size-base;
$font-size-text-intro:          1.375rem;           // 22px

$font-size-small:               0.75rem;            // 12px

$font-size-h1:                  2.25rem;            // 36px
$font-size-h2:                  $font-size-h1;      // 36px
$font-size-h3:                  1rem;               // 16px
$font-size-h4:                  1rem;               // 16px
$font-size-h5:                  1.625rem;           // 26px
$font-size-h1-mobile:           $font-size-h1 * 0.75;

$font-size-header:              $font-size-base;
$font-size-nav-main-sub:        $font-size-small;
$font-size-footer:              $font-size-base;
$font-size-navs-secondary:      $font-size-base;
$font-size-quote:               $font-size-h1;
$font-size-quote-mobile:        $font-size-h1-mobile;
$font-size-numbers:             1.625rem;           // 26px;


// ---------- LINE HEIGHTS ----------

$line-height-base:              1.5285;
$line-height-text:              1.4285;
$line-height-text-intro:        1.2727;

$line-height-h1:                1.1111;
$line-height-h2:                $line-height-h1;
$line-height-h3:                1.375;
$line-height-h4:                1.4;
$line-height-h5:                1.3076;

$line-height-footer:            $line-height-base;
$line-height-navs-secondary:    1.125;
$line-height-quote:             1.27777;
$line-height-numbers:           1.1538;


// ---------- BORDER RADIUS ----------

$border-radius-big:             40px;
$border-radius-small:           20px;
$border-radius-base:            $border-radius-small;


// ---------- BOX SHADOW ----------

$box-shadow-small:              0 0 10px $grey-30;
$box-shadow-big:                0 0 15px $grey-50;
$box-shadow-base:               $box-shadow-small;
$box-shadow-button:             $box-shadow-small;


// ---------- RESPONSIVENESS ----------

// container horizontal gap, which acts as the offset for breakpoints
$gap:                       16px !default;
$spacing-section:           30px;

$mobile-big:                501px !default;
$tablet:                    768px !default;
$desktop:                   960px + 2 * $gap !default;
$widescreen:                1152px + 2 * $gap !default;
$widescreen-enabled:        true !default;
$fullhd:                    1344px + 2 * $gap !default;
$fullhd-enabled:            true !default;

$mobile-menu-breakpoint:    $desktop;

$breakpoints: ("mobile-small": ("until": $mobile-big), "mobile": ("until": $tablet), "tablet": ("from": $tablet), "tablet-only": ("from": $tablet, "until": $desktop), "touch": ("from": $desktop), "desktop": ("from": $desktop), "desktop-only": ("from": $desktop, "until": $widescreen), "until-widescreen": ("until": $widescreen), "widescreen": ("from": $widescreen), "widescreen-only": ("from": $widescreen, "until": $fullhd), "until-fullhd": ("until": $fullhd), "fullhd": ("from": $fullhd)) !default;
