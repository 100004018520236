@use '../../../css' as *;

ul.downloads {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
      margin: 0;
      padding: 0;

      a {
          color: $color-text;
          display: block;
          font-style: normal;
          padding: 6px 0 6px 40px;
          position: relative;
          text-decoration: none;

          &:before {
              background: $grey-base url("#{$icon-download-wd-group}") no-repeat center center;
              background-size: 16px 16px;
              border-radius: 50%;
              content: "";
              display: block;
              height: 24px;
              left: 0;
              position: absolute;
              top: 4px;
              width: 24px;
          }

          &:hover,
          &:focus,
          &:active {
              color: $color-brand-primary;

              &:before {
                  background-color: $color-brand-primary;
                  background-image: url("#{$icon-download-white}");
              }
          }
      }
  }
}