@use '../../../css' as *;

*:focus {
    outline: 0;
}

input[type="text"],
input[type="email"],
input[type="tel"],
select,
textarea {
    background-color: $bg-color-form-elements;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid transparent;
    border-top: 2px solid transparent;
    box-sizing: border-box;
    color: $color-text;
    display: inline-block;
    font-family: $font-family-base;
    font-size: $font-size-text;
    font-weight: 500;
    line-height: $line-height-base;
    min-height: 48px;
    padding: 12px;
    width: 100%;

    form label + & {
        margin-top: 5px;
    }

    &:focus {
        border-bottom: 2px solid $border-color-form-focus;

        .wd-feed &       { border-bottom-color: $color-wd-feed; }
        .wd-food &       { border-bottom-color: $color-wd-food; }
        .wd-pharma-api & { border-bottom-color: $color-wd-pharma-api; }
        .wd-pharma-fdf & { border-bottom-color: $color-wd-pharma-fdf; }
    }
}

input[type="search"] {
    -webkit-appearance: none;
    border-radius: 0 !important;
}

select {
    appearance: none;
    background: $bg-color-form-elements url("#{$icon-select-arrow-down}") no-repeat center right 12px;
    background-size: 12px 6px;
    line-height: normal;
    padding-right: 36px;
}

textarea {
    min-height: 240px;
    overflow: auto;
    resize: vertical;
    vertical-align: top;
}


// checkbox + radio

input[type="checkbox"],
input[type="radio"] {
    background: transparent;
    border: 0;
    height: auto;
    line-height: normal;
    position: relative;
    margin: 0 6px 0 0;
    width: 20px;

    &:before {
        background: $white none no-repeat center center;
        background-size: 10px 8px;
        border: 1px solid currentColor;
        box-sizing: border-box;
        content: "";
        display: block;
        top: -2px;
        position: absolute;
        height: 20px;
        width: 20px;
    }

    &:focus:before {
        border: 2px solid $border-color-form-focus;
    }
}

.form-checkbox,
.form-radio {
    display: block;
    padding-left: 30px;
    position: relative;

    .error-message {
        margin-left: -30px;
    }
}

.form-checkbox input[type="checkbox"],
.form-radio input[type="radio"] {
    position: absolute;
    left: 0;
    top: 2px;
}

input[type="radio"] {
    &:before {
        border-radius: 50%;
    }

    &:checked:after {
        background-color: $color-text;
        border-radius: 50%;
        content: "";
        height: 10px;
        left: 5px;
        position: absolute;
        top: 3px;
        width: 10px;
    }
}

input[type="checkbox"] {
    &:checked:before {
        background-image: url("#{$icon-check}");
    }
}

// errors

.error-message {
    color: $red;
    font-size: $font-size-small;
    margin-top: 6px;
}

input[type="text"],
input[type="email"],
input[type="tel"],
select,
textarea {
    &[aria-invalid="true"] {
        border: 1px solid $red;
    }
}

input[type="checkbox"],
input[type="radio"] {
    &[aria-invalid="true"] {
        &:before {
            border: 1px solid $red;
        }
    }
}

input[type="text"],
input[type="email"],
input[type="tel"],
select,
textarea {
    &[aria-invalid="true"] {
        &:focus {
            border-width: 2px;
            padding: 11px;
        }
    }
}

input[type="checkbox"],
input[type="radio"] {
    &:focus:before {
        border-width: 2px;
    }
}


// button

input[type="submit"] {
    display: block;
    min-height: none;
}

form {
    button,
    input[type="submit"] {
        display: block;
        margin: 0 0 0 auto;
    }
}

@include until($mobile-menu-breakpoint) {
    main form,
    main form.columns,
    main form.columns:last-child {
        margin-bottom: $spacing-section;
    }
}