@use "variables.scss" as *;


// ---------- LISTS ----------

ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        padding: 0;
        margin: 0;
    }
}

// text lists
ul:not(.unstyled):not(:where(nav *, .cms-toolbar *)) {
    li {
        padding-left: 1rem;
        position: relative;

        + li {
            margin-top: 1rem;
        }

        &:before {
            content: "\00B7";
            display: block;
            position: absolute;
            left: 0;
        }
    }

    ul {
        margin-top: 1rem;
    }
}