@use '../../../css' as *;

.nav-secondary {
    margin-bottom: 2rem;

    ul {
        justify-content: flex-start;
    }

    a {
        // aspect ratio 1:1
        display: block;
        padding-bottom: 100%;
        position: relative;
        width: 100%;

        .box {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
        }

        &:hover,
        &:focus,
        &:active {
            > .box {
                transform: scale(1.15);
            }
        }
    }

    .box {
        background-color: $color-wd-group;
        color: $color-text-inverted;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 15px;

        // background color
        &.wd-group                  { background-color: $color-wd-group; }
        &.wd-food                   { background-color: $color-wd-food; }
        &.wd-feed                   { background-color: $color-wd-feed; }
        &.wd-pharma-api             { background-color: $color-wd-pharma-api; }
        &.wd-pharma-fdf             { background-color: $color-wd-pharma-fdf; }

        // marker color
        &:after                     { background-color: $color-wd-group; }
        .wd-group &:after           { background-color: $color-wd-group; }
        .wd-food &:after            { background-color: $color-wd-food; }
        .wd-feed &:after            { background-color: $color-wd-feed; }
        .wd-pharma-api &:after      { background-color: $color-wd-pharma-api; }
        .wd-pharma-fdf &:after      { background-color: $color-wd-pharma-fdf; }
        
        &.wd-group:after, &.text-color-wd-group:after             { background-color: $color-wd-group !important; }
        &.wd-food:after, &.text-color-wd-food:after               { background-color: $color-wd-food !important; }
        &.wd-feed:after, &.text-color-wd-feed:after               { background-color: $color-wd-feed !important; }
        &.wd-pharma-api:after, &.text-color-wd-pharma-api:after   { background-color: $color-wd-pharma-api !important; }
        &.wd-pharma-fdf:after, &.text-color-wd-pharma-fdf:after   { background-color: $color-wd-pharma-fdf !important; }

        // for grey background
        &.bg-color-grey {
            background-color: $grey-base;
            color: $color-wd-group;

            // text color
            .wd-group &      { color: $color-wd-group; }
            .wd-feed &       { color: $color-wd-feed; }
            .wd-food &       { color: $color-wd-food; }
            .wd-pharma-api & { color: $color-wd-pharma-api; }
            .wd-pharma-fdf & { color: $color-wd-pharma-fdf; }
        
            &.text-color-wd-group               { color: $color-wd-group; }
            &.text-color-wd-feed                { color: $color-wd-feed; }
            &.text-color-wd-food                { color: $color-wd-food; }
            &.text-color-wd-pharma-api          { color: $color-wd-pharma-api; }
            &.text-color-wd-pharma-fdf          { color: $color-wd-pharma-fdf; }

            // icon color
            .image > svg {
                fill: none;
                stroke: $color-wd-group;
                stroke-width: 1px;
                
                .wd-feed &        { stroke: $color-wd-feed; }
                .wd-food &        { stroke: $color-wd-food; }
                .wd-pharma-api &  { stroke: $color-wd-pharma-api; }
                .wd-pharma-fdf &  { stroke: $color-wd-pharma-fdf; }
            }
    
            &.text-color-wd-group {
                color: $color-wd-group;
                .image > svg      { stroke: $color-wd-group !important; }
            }
            &.text-color-wd-feed {
                color: $color-wd-feed;
                .image > svg      { stroke: $color-wd-feed !important; }
            }
            &.text-color-wd-food {
                color: $color-wd-food;
                .image > svg      { stroke: $color-wd-food !important; }
            }
            &.text-color-wd-pharma-api {
                color: $color-wd-pharma-api;
                .image > svg      { stroke: $color-wd-pharma-api !important; }
            }
            &.text-color-wd-pharma-fdf {
                color: $color-wd-pharma-fdf;
                .image > svg      { stroke: $color-wd-pharma-fdf !important; }
            }

            // marker color
            &:after                             { background-color: $color-wd-group; }
            .wd-group &:after                   { background-color: $color-wd-group; }
            .wd-food &:after                    { background-color: $color-wd-food; }
            .wd-feed &:after                    { background-color: $color-wd-feed; }
            .wd-pharma-api &:after              { background-color: $color-wd-pharma-api; }
            .wd-pharma-fdf &:after              { background-color: $color-wd-pharma-fdf; }
        }

        &.current {
            cursor: default;
            overflow: visible;
    
            // current/active state indicator
            &:after {
                content: "";
                display: block;
                height: 5px;
                width: 100%;
                position: absolute;
                left: 0;
                bottom: -10px;
    
                @include from ($mobile-menu-breakpoint) {
                    bottom: -21px;
                }
            }
        }
    }

    .image {
        flex: 1 1;
        margin-bottom: 5px;
        max-height: 45%;
        position: relative;

        // icon
        > * {
            height: 100%;
            left: 0;
            margin: 0 auto 5px;
            max-width: 65%;
            position: absolute;
            right: 0;
            width: auto;
        }

        > svg {
            stroke: $white;
            fill: none;
            stroke-width: 1px;
        }
    }

    .text {
        font-size: $font-size-navs-secondary;
        flex: 0 0;
        line-height: $line-height-navs-secondary;
        word-wrap: break-word;

        &:only-child {
            position: absolute;
            bottom: 10px;
            right: 10px;
            left: 10px;
            top: auto;
        }

        &:after {
            content: "";
            display: block;
            background: transparent url("#{$icon-arrow-right}") no-repeat top left;
            background-size: 14px 10px;
            margin-top: 10px;
            height: 10px;
            width: 14px;
        }
    }
}
